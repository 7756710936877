export default {
   params: {
      transType0: "現貨",
      transType1: "合約",
      directionType0: "買入",
      directionType1: "賣出",
      lockStateType0: "運行中",
      lockStateType1: "已結束",
      lockStateType2: "已取消",
   },
   common: {
      amount: "金額",
      voucher: "充值憑證",
      withdraw1: "提現帳戶",
      withdraw2: "選擇類型",
      remark: "備註",
      verify:"認證",
      verifySuccess:"認證通過",
      onlineService: "客戶服務",
      tip1: "請輸入正確的金額",
      tip2: "帳戶餘額不足",
      confirm: "確定",
      cancel: "取消",
      tip: "提示",
      register: "註冊",
      viewMore: "查看更多",
      trade: "交易",
      empty: "暫無內容",
      searchPairs: "搜索幣對",
      and: "和",
      next: "下一步",
      success: "成功",
      more: "更多",
      main: "主圖",
      sub: "幅图",
      copySuccess: "複製成功！",
      copyError: "複製失敗！",
      view: "查看",
      all: "全部",
      goSet: "去設定",
      available: "可用",
      confirmCancel: "確定要取消嗎？",
      copy: "複製",
      submit: "提交",
      send: "發送",
      keep: "繼續下單",
      revenue: "預期收益",
   },
   mine: {
      lastTime: "上次登入",
   },
   head: {
      mineInvite: "邀請好友",
      mineAddress: "取款地址",
      mine: "帳戶中心",
      orderCoin: "現貨訂單",
      orderContract: "合約訂單",
      orderSecond: "期權訂單",
      order: "訂單",
      assetsWithdraw: "提幣",
      assetsRecharge: "充幣",
      assetsTotal: "資產總折合",
      assets: "資產",
      logout: "登出",
      menuTit1: "行情",
      menuTit2: "交易",
      menuTit3: "理財",
      menuTit4: "新幣上線",
      menuTit5: "Contest",
      menuTit6: "Event",
      menuTit7: "博客",
      menuTit8: "Discover",
      menuItem0: "期權交易",
      menuItem1: "幣幣交易",
      menuItem2: "合約交易",
      menuItem3: "Launchpad",
      menuItem4: "投票上幣",
      menuItem5: "Livestream",
      menuItem6: "Offline Event",
      login: "登錄",
      register: "註冊",
      download: "掃碼下載",
      noPermissions: "您的IP地址顯示您處於Coinstore服務限制的國家/地區",
   },
   home: {
      h1: "The First Choice For The Initial Launch",
      p1: "Trade over 50 cryptocurrencies on ICESAS",
      tradeTab1: "幣幣交易",
      tradeTab2: "合約交易",
      tradeTag1: "漲幅榜",
      tradeTag2: "熱門幣",
      tradeTag3: "Prime",
      serviceTit: "多種服務供您選擇",
      serviceTit1: "現貨交易",
      servicedesc1: "以現行市價購買、出售或交易加密貨幣。",
      serviceTit2: "永續合約",
      servicedesc2: "通過做多或做空進行合約交易",
      serviceTit3: "理財",
      servicedesc3: "一鍵質押 日日增收",
      serviceTit4: "Prime",
      servicedesc4: "認購優質的新代幣",
      liveTit: "ICESAS 直播",
      whyTit: "ICESAS 直播",
      whyTit1: "安全、值得信賴",
      whySubtit1: "實時的內部清算、審計與風險管理 冷錢包與多重簽名錢包分離，保障資金安全專業的第三方安全機構審計，專業的第三方安全機構審計",
      whyLink: "查看全部安全機制",
      whyTit2: "強勁的交易撮合引擎",
      whySubtit2: "超低延遲的撮合引擎、龐大的吞吐量、極峰不抖動，高峰不宕機",
      whyTit3: "可信賴的平台",
      whySubtit3: "作為全球排名前50的交易平臺之一，Coinstore嚴格遵循行業規則，不參與市場運作。",
      whyTit4: "快捷的交易系統",
      whySubtit4: "Coinstore為您提供簡潔明瞭的交易介面和便捷的操作流程，讓您更加方便地進行數位資產交易。",
      guideTit: "新手指南",
      guideh1: "建立一個Coinstore帳戶",
      guidep1: "立即註冊，開啟您的交易之旅！",
      guideBtn1: "註冊",
      guideh2: "存款",
      guidep2: "快速充值，輕鬆入賬！",
      guideBtn2: "充幣",
      guideh3: "開始交易",
      guidep3: "開始交易，暢享無限可能！",
      guideBtn3: "幣幣交易",
      readyTop1: "準備開始了嗎？",
      readyMid: "ICESAS 具有先進的技術，為您提供迅捷的買賣和交易體驗。使用我們專為您打造的簡潔平臺安全地進行交易。",
      readyTit: "Scan to download APP",
      readySubtit: "Android and iOS supported",
      readyDownload: "Download on the",
      readyAppStore: "AppStore",
      readyGetin: "GET IT ON",
      readyGoole: "Google Play",
      faqTit: "FAQ",
      faqTit1: "配資體驗金",
      faqCont1: "Futures Credit是為Coinstore用戶提供的數字資金借貸獎勵，幫助用戶了解Coinstore永續合約交易。與Coinstore傳統的期貨交易相比，結合用戶提供的加密資產本金，Futures Credit可以作為Futures Credit交易中相對較大的開倉保證金。",
      faqTit2: "什麼是CoinBox？",
      faqCont2: "Futures CoinBox是Coinstore的一項新產品功能，允許您向朋友發送加密貨幣CoinBox。您可以創建一個加密貨幣CoinBox並與您的朋友分享。當您的朋友通過Coinstore應用程序輸入CoinBox密碼來領取禮物時，他們將從CoinBox收到隨機數量的加密貨幣。 Credit可以作為Futures Credit交易中相對較大的開倉保證金。",
      faqTit3: "初學者賺取錢指南",
      faqCont3: "Futures Coinstore最新的加密貨幣財富管理產品由Coinstore量化團隊精心打造，由經驗豐富的量化投資基金經理、期貨和證券行業的金融精英以及BAT技術專家組成。該團隊於2021年在新加坡成立，專注於數字資產量化管理，具有專業的量化模型方案和管理能力。 Credit可以作為Futures Credit交易中相對較大的開倉保證金。",
      faqTit4: "加入我們的Coinstore直播",
      faqCont4: "Futures Live是一個直播產品，邀請頂級區塊鏈項目和行業專家傳遞最新和最熱門的講座和演講。加入我們，探索更多精彩內容。 Credit可以作為Futures Credit交易中相對較大的開倉保證金。",
      faqTit5: "如何在Coinstore購買加密貨幣",
      faqCont5: "Futures Coinstore為全球用戶提供多家第三方加密貨幣購買服務提供商。產看我們有關加密貨幣購買服務的文章。 Credit可以作為Futures Credit交易中相對較大的開倉保證金。",
      partnerTit: "合作夥伴",
   },
   assets: {
      tit: "總資產",
      recharge: "充幣",
      withdraw: "提幣",
      transfer: "劃轉",
      tab1: "資產總額",
      tab2: "幣幣帳戶",
      tab3: "合約帳戶",
      tab4: "合約量化帳戶",
      tab5: "理財帳戶",
      assetsTotal: "總計",
      assetsReal: "可用",
      assetsFrozen: "凍結",
      proportion: "資產占比",
      tip1: "最小提现金額",
      tip2: "低於最低金額的提现將不會到賬，無法退款",
      tip3: "最小提现金額",
      linkType: "鏈類型",
      rechargeAddress: "充幣地址",
      contractAddress: "合約地址",
      rechargeRecord: "充幣記錄",
      tip4: "您未完成密碼設定，暫不支持充幣",
      withrawAddress: "提現地址",
      placeWithrawAddress: "請輸入提現地址",
      withrawAmount: "提現金額",
      placeWithrawAmount: "請輸入提現金額",
      availableLimit: "可用額度",
      fee: "手續費",
      expectedReceipt: "預計到賬",
      withdrawRecord: "提幣記錄",
   },
   login: {
      login: "登錄",
      register: "註冊",
      email: "郵箱",
      phone: "手機",
      forget: "忘記密碼？",
      noAccount: "沒有帳號？",
      haveAccount: "已有帳號？",
      agree1: "我理解",
      agree2: "用戶協議",
      agree3: "隱私協議",
      resetPassword: "重置密碼",
      securityTip: "為了保證您的資產安全，密碼修改後24小時不可提幣",
   },
   form: {
      send: "發送驗證碼",
      labelCaptcha: "驗證碼",
      errorCaptcha: "請輸入驗證碼",
      labelEmail: "郵箱",
      errorEmail: "請輸入正確的郵箱地址",
      placeEmail: "請輸入郵箱",
      labelPhone: "手機",
      errorPhone: "請輸入正確的手機號",
      placePhone: "請輸入手機號",
      labelPassword: "密碼",
      errorPassword: "請輸入正確的密碼",
      placePassword: "請輸入密碼",
      labelInvite: " 邀請碼(選填）",
      errorInvite: "請輸入邀請碼",
      placeInvite: "請輸入邀請碼",
      placeSelece: "請選擇",
      account_number: "帳戶號碼",
      label_type: "選擇類型",
      place_type: "請選擇類型",
      label_number: "帳戶號碼",
      place_number: "請輸入帳戶號碼",
      wallet_address: '錢包地址',
      bank_name: '銀行名字',
      bank_address: "銀行地址",
      name: "姓名",
      placeName: "請輸入名字",
      payee_address: '收款人地址',
      swift_code: "Swift/Swift Code/BIC",
      aba: "ABA",
      branch_number: "分行名字",
      branch_code: "分行程式碼",
      bank_code: "銀行程式碼",
      phone: "手機號",
      iban: "IBAN",
      sort_code: "Sort Code",
      placeInner: "請輸入內容",
      labelCurrency: "幣種",
      placeCurrency: "請選擇幣種",
      labelNumber: "數量",
      placeNumber: "請輸入數量",
      messageNumber: "請輸入數量",
      labelPrice: "價格",
      placePrice: "請輸入價格",
      placeAmount: "請輸入金額",
      labelJoinMoney: "加入金額",
      labelOldPassword: "舊密碼",
      placeOldPassword: "請輸入舊密碼",
      messageOldPassword: "請輸入舊密碼",
      psdTip: "密碼必須為6 ~ 16比特字母+數位的組合",
      labelNewPassword: "新密碼",
      placeNewPassword: "請輸入新密碼",
      messageNewPassword: "請輸入新密碼",
      labelCrmPassword: "確認密碼",
      placeCrmPassword: "請再次輸入密碼",
      messageCrmPassword: "請再次輸入密碼",
      labelCountry: "國家/地區",
      placeCountry: "請選擇國家",
      messageCountry: "請選擇國家",
      labelId: "id號号",
      messageId: "請輸入id號",
      labelCaptchaEmail: "驗證碼將發送至",
      labelTotalAmount: "總額",
   },
   table: {
      labelName: "名稱",
      labelLastPrice: "最新價",
      labelRang: "24H 漲跌幅",
      labelVolume: "24H 成交額",
      labelVolumeNum: "24H 成交量",
      labelKline: "K線圖",
      labelHeight: "24H 高",
      labelLow: "24H 低",
      labelOperate: "操作",
      labelTime: "時間",
      labelCurrency: "幣種",
      labelAmount: "金額",
      labelState: "狀態",
      state0: "已完成",
      state1: "失敗",
      state2: "已取消",
      withdrawState0: "待稽核",
      withdrawState1: "已完成",
      withdrawState2: "已拒絕",
      labelDirection: "方向",
      labelPrice: "價格",
      labelLever: "杠杆",
      labelHangNum: "手數",
      gmtCreate: "創建時間",
      operate: "操作",
      labelPeriod: "結算周期",
      every: "每",
      hour: "小時",
      day: "天",
      labelFundRate: "資金費率",
      labelDailyRate: "當日資金費率",
      labelLevesCurrency: "投資幣種",
      labelReturn: "年化收益率",
      labelLevesTerm: "投資期限",
      labelStartAmount: "起始金額",
      viewDetails: "查看詳情",
      labelPairs: "交易對",
      labelType: "類型",
      labelEntrustNumber: "委託數量",
      labelTransNumber: "成交數量",
      labelTotalAmount: "成交總額",
      labelEntrustTime: "委託時間",
      labelBuyPrice: "買入價",
      labelBuyNumber: "買入數量",
      labelBuyTime: "購買時間",
      labelSellPrice: "賣出價格",
      labelProfitLoss: "盈虧",
      labelNumber: "數量",
      labelMinerTitle: "理財名稱",
   },
   foot: {
      menuTit1: "關於我們",
      menuTit2: "聯繫我們",
      menuTit3: "資源",
      menuTit4: "幫助",
      menuTit5: "交易",
      menuItem1: "Coinstore團隊",
      menuItem2: "官方驗證通道",
      menuItem3: "商務收款授權",
      menuItem4: "合夥人",
      menuItem5: "走進Coinstore",
      menuItem6: "用戶協議",
      menuItem7: "官方郵箱",
      menuItem8: "Coinstore合約",
      menuItem9: "上幣申請",
      menuItem10: "行情",
      menuItem11: "Coinstore學院",
      menuItem12: "費率標準",
      menuItem13: "提幣手續費&提幣限額",
      menuItem14: "幣種介紹",
      menuItem15: "安全",
      menuItem16: "充幣&提幣狀態",
      menuItem17: "建議與反饋",
      menuItem18: "幫助中心",
      menuItem19: "工單提交",
      menuItem20: "API文檔",
   },
   market: {
      spots: {
         filterTab1: "自選",
         filterTab2: "幣幣行情",
         filterTab3: "合約行情",
         filterTab4: "Launchpad",
         tabAll: "全部",
         tab1: "主區",
         tab2: "創新",
         tab3: "開放區",
         tab4: "Prime",
         tab5: "幣幣",
         tab6: "合約",
         tab7: "USDT永續",
         tab8: "全幣種永續",
      }
   },
   trade:{
      chartMsg: "幣種信息",
      marketPrice:"市價",
      limitPrice:"限價",
      marketPriceTip: "市價委托使用現時市場最優價格進行買賣",
      lever: "杠杆",
      handNum: "手數",
      margin: "保證金",
      assets:"資產",
      balance:"餘額",
      book: "委託簿",
      quantity: "累計量",
      navState0: "等待開倉",
      navState1: "當前持倉",
      navState2: "歷史委託",
      navState3: "當前委託",

      fund: {
         tit1: "合約市場",
         tit2: "資金費率歷史",
         p1: "保險基金來避免投資者的倉位被自動減倉。 該基金用來改進未被執行的强平委託的價格，以避免它們被自動減倉系統接管。",
         p2: "保險基金額的增長來自强平委託在市場上於優於破產價的價格成交。",
         p3: "可以查看下麵的保險基金的當前和歷史餘額。",
         tab1: "USDT永續",
         tab2: "全幣種永續",
         contract: "合約",
      },
   },
   earn: {
      tit: "壹鍵質押，日日增收",
      tab1: "定期理財",
      orderTit: "理財訂單",
      labelTotalApr: "年化收益率",
      labelTimeLimit: "項目週期",
      labelMoney: "加入金額",
      labelType: "回款管道",
      labelType1: "到期還本付息",
      labelNowBalance: "當前餘額",
   },
   invite: {
      tit: "邀請好友",
      p: "掃描此二維碼邀請好友",
      iviteLink: "邀請連結",
      registerCode: "註冊碼",
   },
   noPermissions: {
      tip: "封不起，您所在的地區暫時焦法防周此綱站，黑法提供服移",
   },
   order: {
      tit: "訂單記錄",
      tab1: "期權訂單",
      tab2: "幣幣訂單",
      tab3: "合約訂單",
      tab4: "理財訂單",
      filterType0: "全部類型",
      filterType1: "已完成",
      filterType2: "已取消",
      filterType3: "當前持倉",
      filterDirection0: "全部方向",
      filterDirection1: "買入",
      filterDirection2: "賣出",
   },
   personal: {
      tit1: "安全等級",
      tit2: "登入密碼",
      tit3: "交易密碼",
      tit4: "安全設置",
      strong: "強",
      setting: "設定",
      settings: "設定",
      change: "更改",
      idTip1: "身份認證",
      idTip2: "進行身份認證才可進行其餘操作",
      withdrawTip1: "取款地址",
      withdrawTip2: "請進行綁定取款地址",
      loginPsdTit: "設定登入密碼",
      withdrawPsdTit: "設定提現密碼",
      idTit: "實名認證",
      phoneTit: "綁定手機",
      phoneTip: "手機更換後24小時禁止取消",
      walletTit: "綁定錢包",
      accountNumber: "帳戶號碼",
      addAddress: "添加地址",

   },
   contest: {
      hot: "熱門活動",
   },
   city: {
      albania: "阿爾巴尼亞",
      algeria: "阿爾及利亞",
      argentina: "阿根廷",
      armenia: "亞美尼亞",
      australia: "澳大利亞",
      pakistan: "巴基斯坦",
      austria: "奧地利",
      bahrain: "巴林",
      belgium: "比利時",
      bosnia_and_Herzegovina: "波士尼亞和黑塞哥維那",
      brazil: "巴西",
      brunei: "汶萊",
      bulgaria: "保加利亞",
      cambodia: "柬埔寨",
      canada: "加拿大",
      cameroon: "喀麥隆",
      chile: "智利",
      colombia: "哥倫比亞",
      costa_Rica: "哥斯达黎加",
      croatia: "克羅地亞",
      cyprus: "賽普勒斯",
      czech_Republic: "捷克共和國",
      denmark: "丹麥",
      dominican_Republic: "多米尼加共和国",
      egypt: "埃及",
      estonia: "愛沙尼亞",
      ethiopia: "埃塞俄比亚",
      finland: "芬蘭",
      france: "法國",
      georgia: "格魯吉亞",
      germany: "德國",
      ghana: "加納",
      greece: "希臘",
      guyana: "蓋亞那",
      honduras: "洪都拉斯",
      hong_Kong_China: "中國香港",
      hungary: "匈牙利",
      iceland: "冰島",
      ireland: "愛爾蘭",
      italy: "義大利",
      india: "印度",
      indonesia: "印度尼西亞",
      israel: "以色列",
      iran: "伊朗",
      iraq: "伊拉克",
      japan: "日本",
      kazakstan: "哈薩克共和國",
      kenya: "肯尼亚",
      korea: "韓國",
      kuwait: "科威特",
      kyrgyzstan: "吉爾吉斯斯坦",
      laos: "老撾",
      latvia: "拉脫維亞",
      lithuania: "立陶宛",
      luxembourg: "盧森堡",
      macao_China: "中國澳門",
      macedonia: "馬其頓",
      malaysia: "馬來西亞",
      malta: "馬爾他",
      mexico: "墨西哥",
      moldova: "摩爾達瓦",
      monaco: "摩納哥",
      mongolia: "蒙古",
      montenegro: "黑山共和國",
      morocco: "摩洛哥",
      myanmar: "緬甸",
      netherlands: "荷蘭",
      new_Zealand: "新西蘭",
      nepal: "尼泊爾",
      nigeria: "尼日利亞",
      norway: "挪威",
      oman: "阿曼",
      palestine: "巴勒斯坦",
      panama: "巴拿馬",
      paraguay: "巴拉圭",
      peru: "秘魯",
      philippines: "菲律賓",
      poland: "波蘭",
      portugal: "葡萄牙",
      puerto_Rico: "波多黎各",
      qatar: "卡塔爾",
      romania: "羅馬尼亞",
      russia: "俄羅斯",
      republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥共和国",
      rwanda: "卢旺达",
      saudi_Arabia: "沙烏地阿拉伯",
      serbia: "塞爾維亞",
      singapore: "新加坡",
      slovakia: "斯洛伐克",
      slovenia: "斯洛文尼亞",
      south_Africa: "南非",
      spain: "西班牙",
      sri_Lanka: "斯里蘭卡",
      sweden: "瑞典",
      switzerland: "瑞士",
      taiwan_China: "中國臺灣",
      tajikistan: "塔吉克共和國",
      tanzania: "坦尚尼亞",
      thailand: "泰國",
      turkey: "土耳其",
      turkmenistan: "土庫曼共和國",
      ukraine: "烏克蘭",
      united_Arab_Emirates: "阿拉伯聯合大公國",
      united_Kingdom: "英國",
      united_States: "美國",
      uzbekistan: "烏茲別克",
      venezuela: "委內瑞拉",
      vietnam: "越南",
      afghanistan: "阿富汗",
      angola: "安哥拉",
      azerbaijan: "阿塞拜疆",
      bangladesh: "孟加拉",
      belarus: "白俄羅斯",
      belize: "貝裡斯",
      benin: "貝南",
      bhutan: "不丹",
      bolivia: "玻利維亞",
      botswana: "博茨瓦纳",
      british_Virgin_Islands: "英屬維京群島",
      burkina_Faso: "伯基納法索",
      burundi: "布隆迪",
      cape_Verde: "佛得角",
      cayman_Islands: "開曼群島",
      central_African_Republic: "中非共和國",
      chad: "乍得",
      comoros: "科摩羅",
      the_Republic_of_the_Congo: "剛果（布）",
      democratic_Republic_of_the_Congo: "剛果（金）",
      djibouti: "吉佈提",
      ecuador: "厄瓜多尔",
      el_Salvador: "薩爾瓦多",
      equatorial_Guinea: "赤道幾內亞",
      eritrea: "厄立特里亚",
      fiji: "斐濟",
      gabon: "加蓬",
      gambia: "岡比亞",
      greenland: "格陵蘭",
      guatemala: "危地马拉",
      guinea: "幾內亞",
      haiti: "海地",
      isle_of_Man: "馬恩岛",
      cote_d_Ivoire: "科特迪瓦",
      jamaica: "牙買加",
      jordan: "約旦",
      lebanon: "黎巴嫩",
      lesotho: "萊索托",
      liberia: "利比里亚",
      libya: "利比亞",
      madagascar: "馬达加斯加",
      malawi: "馬拉维",
      maldives: "馬尔代夫",
      mali: "馬里",
      mauritania: "毛里塔尼亞",
      mauritius: "毛里求斯",
      mozambique: "莫桑比克",
      namibia: "納米比亞",
      nicaragua: "尼加拉瓜",
      republic_of_Niger: "尼日尔",
      north_Korea: "朝鮮",
      reunion: "留尼汪",
      san_Marino: "聖馬力諾",
      senegal: "塞內加爾",
      sierra_Leone: "塞拉利昂",
      somalia: "索馬里",
      sudan: "蘇丹",
      suriname: "蘇里南",
      eswatini: "斯威士兰",
      syria: "敘利亞",
      togo: "多哥",
      tonga: "汤加",
      tunisia: "突尼斯",
      united_States_Virgin_Islands: "美屬維爾京群島",
      uganda: "烏干達",
      uruguay: "烏拉圭",
      vatican: "梵蒂岡",
      yemen: "也门",
      yugoslavia: "南斯拉夫",
      zambia: "赞比亚",
      zimbabwe: "津巴布韦",
      china: "中國",
   }
};






