export default {
    params: {
       transType0: "Merce in stock",
       transType1: "Contrattuale",
       directionType0: "Buy-in",
       directionType1: "Reach (prezzo in un'asta)",
       lockStateType0: "Corsa",
       lockStateType1: "Chiuso",
       lockStateType2: "Annullato",
    },
    common: {
        amount: "Amount",
        voucher: "Recharge Voucher",
        withdraw1: "Conto di prelievo",
        withdraw2: "Seleziona tipo",
        remark: "Osservazioni",
        verify:"Accreditamento",
       verifySuccess:"Accreditamento superato",
       onlineService: "Servizio clienti",
       tip1: "Inserire l'importo corretto",
       tip2: "Saldo del conto insufficiente",
       confirm: "Definire",
       cancel: "Cancellazioni",
       tip: "Attirare l'attenzione su qcs.",
       register: "Iscrizione",
       viewMore: "Per saperne di più",
       trade: "Rapporti",
       empty: "Nessun contenuto al momento",
       searchPairs: "Ricerca coppie di valute",
       and: "Rispondere con il canto",
       next: "Il passo successivo",
       success: "Successi",
       more: "Di più",
       main: "Immagine principale",
       sub: "Immagine",
       copySuccess: "Copiare il successo！",
       copyError: "Fallimento della riproduzione！",
       view: "Controllare",
       all: "Completo",
       goSet: "Procedere con l'impostazione",
       available: "Usabilità",
       confirmCancel: "È sicuro di voler cancellare?？",
       copy: "Fare una copia di",
       submit: "Presentare (un rapporto, ecc.)",
       send: "Invio",

    },
    mine: {
       lastTime: "Ultimo accesso",
    },
    head: {
       mineInvite: "Invitare gli amici",
       mineAddress: "Indirizzo di ritiro",
       mine: "Centro di calcolo",
       orderCoin: "Ordine spot",
       orderContract: "Ordine di contratto",
       orderSecond: "Ordini di opzioni",
       order: "Ordine (di acquisto)",
       assetsWithdraw: "Prelevare denaro",
       assetsRecharge: "Ricarica a moneta",
       assetsTotal: "Ammortamento totale delle attività",
       assets: "Responsabilità",
       logout: "Apparire (in un giornale, ecc.)",
       menuTit1: "La situazione attuale del mercato",
       menuTit2: "Rapporti",
       menuTit3: "Gestione finanziaria",
       menuTit4: "Nuove monete in linea",
       menuTit5: "Contest",
       menuTit6: "Event",
       menuTit7: "Blog (parola d'ordine)",
       menuTit8: "Discover",
       menuItem0: "Trading di opzioni",
       menuItem1: "Commercio di monete",
       menuItem2: "Negoziazione di contratti",
       menuItem3: "Launchpad",
       menuItem4: "Vota su Moneta",
       menuItem5: "Livestream",
       menuItem6: "Offline Event",
       login: "Accedi",
       register: "Iscrizione",
       download: "Scansione del codice per il download",
       noPermissions: "Il vostro indirizzo IP indica i paesi in cui i servizi di ICESAS sono limitati.",
    },
    home: {
       h1: "The First Choice For The Initial Launch",
       p1: "Trade over 50 cryptocurrencies on ICESAS",
       tradeTab1: "Commercio di monete",
       tradeTab2: "Negoziazione di contratti",
       tradeTag1: "Aumento e diminuzione delle azioni in un giorno di negoziazione",
       tradeTag2: "Monete calde",
       tradeTag3: "Prime",
       serviceTit: "Un'ampia gamma di servizi tra cui scegliere",
       serviceTit1: "Transazione spot",
       servicedesc1: "Acquistare, vendere o scambiare criptovalute al prezzo di mercato prevalente.",
       serviceTit2: "Contratto perpetuo",
       servicedesc2: "Negoziare i contratti andando lunghi o corti",
       serviceTit3: "Gestione finanziaria",
       servicedesc3: "Impegno con un solo clic per aumentare le entrate giornaliere",
       serviceTit4: "Prime",
       servicedesc4: "Abbonarsi a nuovi gettoni di qualità",
       liveTit: "ICESAS in diretta streaming",
       whyTit: "ICESAS in diretta streaming",
       whyTit1: "Sicuro e affidabile",
       whySubtit1: "Compensazione interna in tempo reale, auditing e gestione del rischio I portafogli a freddo sono separati da quelli a firma multipla per salvaguardare i fondi Audit professionale di organizzazioni di sicurezza di terze parti, Audit professionale di organizzazioni di sicurezza di terze parti",
       whyLink: "Visualizza tutti i meccanismi di sicurezza",
       whyTit2: "Potente motore di aggregazione degli affari",
       whySubtit2: "Motore di aggregazione a bassissima latenza, throughput massiccio, nessun jitter ai picchi estremi, nessun tempo di inattività ai picchi",
       whyTit3: "Piattaforme affidabili",
       whySubtit3: "Essendo una delle prime 50 piattaforme di trading al mondo, ICESAS segue rigorosamente le regole del settore e non partecipa alle operazioni di mercato.",
       whyTit4: "Sistema di trading veloce",
       whySubtit4: "ICESAS offre un'interfaccia di trading semplice e chiara e un processo operativo conveniente, che consente di negoziare gli asset digitali in modo più comodo.",
       guideTit: "Guida per principianti",
       guideh1: "Creare un conto ICESAS",
       guidep1: "Iscriviti ora e inizia il tuo viaggio nel trading!",
       guideBtn1: "Iscrizione",
       guideh2: "Risparmiare in banca",
       guidep2: "Ricarica rapida e accredito facile!",
       guideBtn2: "Ricarica a moneta",
       guideh3: "Inizio dell'attività commerciale",
       guidep3: "Iniziare a fare trading Iniziare a fare trading e godere di infinite possibilità!",
       guideBtn3: "Commercio di monete",
       readyTop1: "Pronti per iniziare？",
       readyMid: "ICESAS dispone di una tecnologia avanzata per offrirvi un'esperienza di acquisto, vendita e trading veloce. Negoziate in modo sicuro con la nostra semplice piattaforma costruita apposta per voi.",
       readyTit: "Scan to download APP",
       readySubtit: "Android and iOS supported",
       readyDownload: "Download on the",
       readyAppStore: "AppStore",
       readyGetin: "GET IT ON",
       readyGoole: "Google Play",
       faqTit: "FAQ",
       faqTit1: "Esperienza di finanziamento",
       faqCont1: "Il Futures Credit è un bonus di prestito di denaro digitale per gli utenti di ICESAS per aiutarli a comprendere il trading di contratti perpetui di ICESAS. Rispetto al trading di futures tradizionale di ICESAS, in combinazione con l'importo principale di criptovalute fornito dall'utente, il Futures Credit può essere utilizzato come margine di apertura relativamente grande per il trading di Futures Credit.",
       faqTit2: "Che cos'è CoinBox?",
       faqCont2: "Futures CoinBox è una nuova funzionalità di ICESAS che consente di inviare una CoinBox di criptovalute a un amico. Quando il vostro amico inserirà la sua password CoinBox tramite l'app ICESAS per ricevere il regalo, riceverà una quantità casuale di criptovaluta dalla CoinBox. Il credito può essere utilizzato come margine relativamente ampio per aprire posizioni nel trading di Futures Credit.",
       faqTit3: "Guida per principianti al guadagno",
       faqCont3: "Futures L'ultimo prodotto di gestione patrimoniale delle criptovalute di ICESAS è realizzato dal team quantitativo di ICESAS, composto da esperti gestori di fondi di investimento quantitativi, élite finanziarie del settore dei futures e dei titoli e tecnologi della BAT. Il team è stato fondato a Singapore nel 2021 e si concentra sulla gestione quantitativa degli asset digitali con soluzioni professionali di modellazione quantitativa e capacità di gestione. Il credito può essere utilizzato come margine di apertura relativamente ampio nel trading di Futures Credit.",
       faqTit4: "Unitevi a noi in diretta su ICESAS",
       faqCont4: "Futures Live è un prodotto di streaming in diretta con i migliori progetti di blockchain di memoria e gli esperti del settore che tengono gli ultimi e più caldi discorsi e presentazioni. Unisciti a noi per scoprire di più.Il credito può essere utilizzato come margine di apertura relativamente ampio nel trading di Futures Credit.",
       faqTit5: "Come acquistare criptovalute su ICESAS",
       faqCont5: "Futures ICESAS offre diversi fornitori terzi di servizi di acquisto di criptovalute agli utenti di tutto il mondo. Il credito può essere utilizzato come margine di apertura relativamente ampio per le operazioni di credito sui futures.",
       partnerTit: "Partner cooperativo",
    },
    assets: {
       tit: "Totale attività",
       recharge: "Ricarica a moneta",
       withdraw: "Prelevare denaro",
       transfer: "Trasferimento",
       tab1: "Totale attività",
       tab2: "Conto Moneta",
       tab3: "Conto contrattuale",
       tab4: "Conto quantitativo contrattuale (CQA)",
       tab5: "Conto finanziario",
       assetsTotal: "Totale (complessivo)",
       assetsReal: "Usabilità",
       assetsFrozen: "Congelamento (prestito, salario, prezzo, ecc.)",
       proportion: "Percentuale di attività",
       tip1: "Importo minimo di prelievo",
       tip2: "I prelievi inferiori all'importo minimo non saranno accreditati e non potranno essere rimborsati.",
       tip3: "Importo minimo di prelievo",
       linkType: "Tipo di catena",
       rechargeAddress: "Indirizzo di ricarica",
       contractAddress: "Indirizzo del contratto",
       rechargeRecord: "Record di riempimento monete",
       tip4: "Non avete completato l'impostazione della password, non supportate la ricarica di monete per il momento",
       withrawAddress: "Indirizzo di ritiro",
       placeWithrawAddress: "Inserire l'indirizzo di prelievo",
       withrawAmount: "Prelievo di contanti",
       placeWithrawAmount: "Inserire l'importo del prelievo",
       availableLimit: "Credito disponibile",
       fee: "Spese di gestione",
       expectedReceipt: "Arrivo previsto",
       withdrawRecord: "Registri dei prelievi di monete",
    },
    login: {
       login: "Accedi",
       register: "Iscrizione",
       email: "Casella di posta elettronica",
       phone: "Telefoni cellulari",
       forget: "Password dimenticata?？",
       noAccount: "Nessun conto？",
       haveAccount: "Avete già un account？",
       agree1: "Capisco.",
       agree2: "Accordo con l'utente",
       agree3: "Dichiarazione sulla privacy",
       resetPassword: "Reimpostare la password",
       securityTip: "Al fine di garantire la sicurezza dei propri beni, non è possibile prelevare monete 24 ore dopo la modifica della password.",
    },
    form: {
       send: "CAPTCHA",
       labelCaptcha: "CAPTCHA, un tipo di test di sfida-risposta (informatica)",
       errorCaptcha: "Inserire il codice di verifica",
       labelEmail: "Casella di posta elettronica",
       errorEmail: "Inserire l'indirizzo e-mail corretto",
       placeEmail: "Inserisci il tuo indirizzo e-mail",
       labelPhone: "Telefoni cellulari",
       errorPhone: "Inserire il numero di cellulare corretto",
       placePhone: "Inserire il numero di cellulare",
       labelPassword: "Crittografico",
       errorPassword: "Inserire la password corretta",
       placePassword: "Inserire la password",
       labelInvite: " Codice invito (facoltativo)",
       errorInvite: "Inserire il codice di invito",
       placeInvite: "Inserire il codice di invito",
       placeSelece: "Selezionare",
       account_number: "Numero di conto",
       label_type: "Selezionare il tipo",
       place_type: "Selezionare il tipo",
       label_number: "Numero di conto",
       place_number: "Numero di conto",
       wallet_address: 'Indirizzo del portafoglio',
       bank_name: 'Nome della banca',
       bank_address: "Indirizzo della banca",
       name: "Nome e cognome",
       placeName: "Inserire un nome",
       payee_address: 'Indirizzo del beneficiario',
       swift_code: "Swift/Swift Code/BIC",
       aba: "ABA",
       branch_number: "Nome della filiale",
       branch_code: "Codice di linea",
       bank_code: "Codice bancario",
       phone: "Numero di telefono cellulare",
       iban: "IBAN",
       sort_code: "Sort Code",
       placeInner: "Inserire il contenuto",
       labelCurrency: "Tipo di valuta",
       placeCurrency: "Selezionare la valuta",
       labelNumber: "Quantità",
       placeNumber: "Inserire la quantità",
       messageNumber: "Inserire la quantità",
       labelPrice: "Prezzi",
       placePrice: "Inserire un prezzo",
       placeAmount: "Inserire l'importo",
       labelJoinMoney: "Importo dell'adesione",
       labelOldPassword: "Vecchia password",
       placeOldPassword: "Inserire la vecchia password",
       messageOldPassword: "Inserire la vecchia password",
       psdTip: "La password deve essere una combinazione di lettere + cifre da 6 a 16 bit.",
       labelNewPassword: "Nuova password",
       placeNewPassword: "Inserire una nuova password",
       messageNewPassword: "Inserire una nuova password",
       labelCrmPassword: "Conferma la password",
       placeCrmPassword: "Inserire nuovamente la password",
       messageCrmPassword: "Inserire nuovamente la password",
       labelCountry: "Paese/area",
       placeCountry: "Selezionare un paese",
       messageCountry: "Selezionare un paese",
       labelId: "Numero id",
       messageId: "Inserire il numero identificativo",
       labelCaptchaEmail: "Il codice di verifica verrà inviato a",
       labelTotalAmount: "Totale (importo o valore)",
    },
    table: {
       labelName: "Nome (di una cosa)",
       labelLastPrice: "Ultimo prezzo",
       labelRang: "24H Su e giù",
       labelVolume: "Fatturato 24H",
       labelVolumeNum: "Volume 24H",
       labelKline: "Grafico della linea K",
       labelHeight: "24H Alto",
       labelLow: "24H Basso",
       labelOperate: "Impianto di perforazione",
       labelTime: "Tempi",
       labelCurrency: "Tipo di valuta",
       labelAmount: "Somma di denaro",
       labelState: "Stato di fatto",
       state0: "Fatto",
       state1: "Fallire (ad es. esperimenti)",
       state2: "Annullato",
       withdrawState0: "In attesa di approvazione",
       withdrawState1: "Fatto",
       withdrawState2: "Rifiutato",
       labelDirection: "Orientamenti",
       labelPrice: "Prezzi",
       labelLever: "Barra di trazione",
       labelHangNum: "Numero ordinale",
       gmtCreate: "Tempo di creazione",
       operate: "Impianto di perforazione",
       labelPeriod: "Ciclo di insediamento",
       every: "Ciascuno",
       hour: "Ore",
       day: "Giorno",
       labelFundRate: "Tasso di finanziamento",
       labelDailyRate: "Tasso di finanziamento in giornata",
       labelLevesCurrency: "Valuta di investimento",
       labelReturn: "Tasso di rendimento annualizzato",
       labelLevesTerm: "Periodo di investimento",
       labelStartAmount: "Importo iniziale",
       viewDetails: "Visualizza dettagli",
       labelPairs: "Controparte della transazione",
       labelType: "Tipologia",
       labelEntrustNumber: "Numero di commissioni",
       labelTransNumber: "Numero di transazioni",
       labelTotalAmount: "Importo totale della transazione",
       labelEntrustTime: "Tempo di messa in servizio",
       labelBuyPrice: "Prezzo dell'offerta",
       labelBuyNumber: "Quantità di acquisto",
       labelBuyTime: "Momento dell'acquisto",
       labelSellPrice: "Tasso di vendita",
       labelProfitLoss: "Cera e calante",
       labelNumber: "Quantità",
       labelMinerTitle: "Nome finanziario",
    },
    foot: {
       menuTit1: "Chi siamo",
       menuTit2: "Contatto",
       menuTit3: "Risorsa (come la manodopera o il turismo)",
       menuTit4: "Mano",
       menuTit5: "Rapporti",
       menuItem1: "Team ICESAS",
       menuItem2: "Canale di autenticazione",
       menuItem3: "Autorizzazione alla riscossione commerciale",
       menuItem4: "Associato",
       menuItem5: "Informazioni su ICESAS",
       menuItem6: "Accordo con l'utente",
       menuItem7: "Email ufficiale",
       menuItem8: "Contratti ICESAS",
       menuItem9: "Applicazione della moneta",
       menuItem10: "La situazione attuale del mercato",
       menuItem11: "Accademia del ICESAS",
       menuItem12: "Tariffe standard",
       menuItem13: "Tassa di prelievo e limite di prelievo",
       menuItem14: "Descrizione della valuta",
       menuItem15: "Fideiussione",
       menuItem16: "Stato di riempimento e prelievo",
       menuItem17: "Suggerimenti e feedback",
       menuItem18: "Centro di assistenza",
       menuItem19: "Presentazione dell'ordine di lavoro",
       menuItem20: "Documentazione API",
    },
    market: {
       spots: {
          filterTab1: "Self-service",
          filterTab2: "Citazioni di monete",
          filterTab3: "Mercato dei contratti",
          filterTab4: "Launchpad",
          tabAll: "Completo",
          tab1: "Area principale",
          tab2: "Aprire nuovi sentieri",
          tab3: "Area aperta",
          tab4: "Prime",
          tab5: "Monete",
          tab6: "Contrattuale",
          tab7: "USDT per sempre",
          tab8: "Valuta intera Perpetua",
       }
    },
    trade:{
       chartMsg: "Informazioni sulla valuta",
       marketPrice:"Valore di mercato",
       limitPrice:"Limite di prezzo",
       marketPriceTip: "Gli ordini di mercato vengono acquistati e venduti utilizzando il prezzo migliore del mercato in quel momento.",
       lever: "Barra di trazione",
       handNum: "Numero ordinale",
       margin: "Margine (nella negoziazione di derivati)",
       assets:"Responsabilità",
       balance:"Bilanci",
       book: "Libro della commissione",
       quantity: "Importo cumulativo",
       navState0: "In attesa di aprire una posizione",
       navState1: "Posizione attuale",
       navState2: "Commissioni storiche",
       navState3: "Commissioni attuali",

       fund: {
          tit1: "Mercato dei contratti",
          tit2: "Storia del tasso di finanziamento",
          p1: "Un fondo assicurativo per evitare che le posizioni degli investitori vengano ridotte automaticamente. Il fondo viene utilizzato per migliorare il prezzo dei mandati chiusi non eseguiti per evitare che vengano rilevati dal sistema di riduzione automatica delle posizioni.",
          p2: "L'aumento dell'importo del fondo assicurativo deriva da forti mandati piatti che vengono scambiati sul mercato a un prezzo migliore di quello di fallimento.",
          p3: "I saldi attuali e storici dei fondi assicurativi possono essere visualizzati di seguito.",
          tab1: "USDT per sempre",
          tab2: "Valuta intera Perpetua",
          contract: "Contrattuale",
       },
    },
    earn: {
       tit: "Impegno con un solo clic, per aumentare le entrate giorno dopo giorno",
       tab1: "Finanziamento a tempo determinato",
       orderTit: "Ordini finanziari",
       labelTotalApr: "Tasso di rendimento annualizzato",
       labelTimeLimit: "Ciclo del progetto",
       labelMoney: "Importo dell'adesione",
       labelType: "Metodo di ammortamento",
       labelType1: "Servizio del debito a scadenza",
       labelNowBalance: "Saldo attuale",
    },
    invite: {
       tit: "Invitare gli amici",
       p: "Scansionate questo codice QR per invitare gli amici",
       iviteLink: "Link all'invito",
       registerCode: "Codice di registrazione",
    },
    noPermissions: {
       tip: "Mi dispiace, la vostra zona è temporaneamente Jiao legge difesa settimana questa stazione piattaforma, la legge nera per fornire servizi per spostare!",
    },
    order: {
       tit: "Registrazione dell'ordine",
       tab1: "Ordini di opzioni",
       tab2: "Ordini di monete",
       tab3: "Ordine di contratto",
       tab4: "Ordini finanziari",
       filterType0: "Tutti i tipi",
       filterType1: "Fatto",
       filterType2: "Annullato",
       filterType3: "Posizione attuale",
       filterDirection0: "Gamma completa di direzioni",
       filterDirection1: "Buy-in",
       filterDirection2: "Reach (prezzo in un'asta)",
    },
    personal: {
       tit1: "Livello di sicurezza",
       tit2: "Password di accesso",
       tit3: "Password di trading",
       tit4: "Impostazioni di sicurezza",
       strong: "Vigoroso",
        setting: "Allestimento",
        settings: "Allestimento",
       change: "Variazione",
       idTip1: "Autenticare",
       idTip2: "Autenticazione prima di procedere con il resto dell'operazione",
       withdrawTip1: "Indirizzo di ritiro",
       withdrawTip2: "Si prega di vincolare l'indirizzo di prelievo",
       loginPsdTit: "Impostazione della password di accesso",
       withdrawPsdTit: "Impostazione di una password per i prelievi",
       idTit: "Autenticazione con nome reale",
       phoneTit: "Incollaggio del telefono cellulare",
       phoneTip: "Nessuna cancellazione per 24 ore dopo il cambio di telefono",
       walletTit: "Portafoglio per la rilegatura",
       accountNumber: "Numero di conto",
       addAddress: "Aggiungi indirizzo",

    },
    contest: {
       hot: "Eventi caldi",
    },
    city: {
         albania: "Albania",
         algeria: "Algeria",
         argentina: "Argentina",
         armenia: "Armenia",
         australia: "Australia",
         pakistan: "Pakistan",
         austria: "Austria",
         bahrain: "Bahrein",
         belgium: "Belgio",
         bosnia_and_Herzegovina: "Bosnia_Erzegovina",
         brazil: "Brasile",
         brunei: "Brunei",
         bulgaria: "Bulgaria",
         cambodia: "Cambogia",
         canada: "Canada",
         cameroon: "Camerun",
         chile: "Cile",
         colombia: "Colombia",
         costa_Rica: "Costa Rica",
         croatia: "Croazia",
         cyprus: "Cipro",
         czech_Republic: "Repubblica Ceca",
         denmark: "Danimarca",
         dominican_Republic: "Repubblica Dominicana",
         egypt: "Egitto",
         estonia: "Estonia",
         ethiopia: "Etiopia",
         finland: "Finlandia",
         france: "Francia",
         georgia: "Georgia",
         germany: "Germania",
         ghana: "Ghana",
         greece: "Grecia",
         guyana: "Guyana",
         honduras: "Honduras",
         hong_Kong_China: "Hong Kong, Cina",
         hungary: "Ungheria",
         iceland: "Islanda",
         ireland: "Irlanda",
         italy: "Italia",
         india: "India",
         indonesia: "Indonesia",
         israel: "Israele",
         iran: "Iran",
         iraq: "Iraq",
         japan: "Giappone",
         kazakstan: "Kazakistan",
         kenya: "Kenya",
         korea: "Corea",
         kuwait: "Kuwait",
         kyrgyzstan: "Kirghizistan",
         laos: "Laos",
         latvia: "Lettonia",
         lithuania: "Lituania",
         luxembourg: "Lussemburgo",
         macao_China: "Macao, Cina",
         macedonia: "Macedonia",
         malaysia: "Malaysia",
         malta: "Malta",
         mexico: "Messico",
         moldova: "Moldavia",
         monaco: "Monaco",
         mongolia: "Mongolia",
         montenegro: "Repubblica di Montenegro",
         morocco: "Marocco",
         myanmar: "Myanmar",
         netherlands: "Paesi Bassi",
         new_Zealand: "Nuova Zelanda",
         nepal: "Nepal",
         nigeria: "Nigeria",
         norway: "Norvegia",
         oman: "Oman",
         palestine: "Palestina",
         panama: "Panama",
         paraguay: "Paraguay",
         peru: "Perù",
         philippines: "Filippine",
         poland: "Polonia",
         portugal: "Portogallo",
         puerto_Rico: "Puerto Rico",
         qatar: "Qatar",
         romania: "Romania",
         russia: "Russia",
         republic_of_Trinidad_and_Tobago: "Repubblica di Trinidad e Tobago",
         rwanda: "Ruanda",
         saudi_Arabia: "Arabia Saudita",
         serbia: "Serbia",
         singapore: "Singapore",
         slovakia: "Slovacchia",
         slovenia: "Slovenia",
         south_Africa: "Sudafrica",
         spain: "Spagna",
         sri_Lanka: "Sri Lanka",
         sweden: "Svezia",
         switzerland: "Svizzera",
         taiwan_China: "Taiwan, Cina",
         tajikistan: "Tagikistan",
         tanzania: "Tanzania",
         thailand: "Thailandia",
         turkey: "Türkiye",
         turkmenistan: "Turkmenistan",
         ukraine: "Ucraina",
         united_Arab_Emirates: "Emirati Arabi Uniti",
         united_Kingdom: "Gran Bretagna",
         united_States: "Stati Uniti",
         uzbekistan: "Uzbekistan",
         venezuela: "Venezuela",
         vietnam: "Vietnam",
         afghanistan: "Afghanistan",
         angola: "Angola",
         azerbaijan: "Azerbaigian",
         bangladesh: "Bangladesh",
         belarus: "Bielorussia",
         belize: "Belize",
         benin: "Benin",
         bhutan: "Bhutan",
         bolivia: "bolivia",
         botswana: "Botswana",
         british_Virgin_Islands: "Isole Vergini Britanniche",
         burkina_Faso: "burkina faso",
         burundi: "Burundi",
         cape_Verde: "Capo Verde",
         cayman_Islands: "Isole Cayman",
         central_African_Republic: "Repubblica Centrafricana",
         chad: "Ciad",
         comoros: "Comore",
         the_Republic_of_the_Congo: "Congo (Brazzaville)",
         democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
         djibouti: "Gibuti",
         ecuador: "Ecuador",
         el_Salvador: "El Salvador",
         equatorial_Guinea: "Guinea Equatoriale",
         eritrea: "Eritrea",
         fiji: "Figi",
         gabon: "Gabon",
         gambia: "Gambia",
         greenland: "Groenlandia",
         guatemala: "Guatemala",
         guinea: "Guinea",
         haiti: "Haiti",
         isle_of_Man: "Isola di Man",
         cote_d_Ivoire: "Costa d'Avorio",
         jamaica: "Giamaica",
         jordan: "Giamaica",
         lebanon: "Libano",
         lesotho: "Lesotho",
         liberia: "Liberia",
         libya: "Libia",
         madagascar: "Madagascar",
         malawi: "Malawi",
         maldives: "Maldive",
         mali: "Mali",
         mauritania: "Mauritania",
         mauritius: "Mauritius",
         mozambique: "Mozambico",
         namibia: "Namibia",
         nicaragua: "Nicaragua",
         republic_of_Niger: "Niger",
         north_Korea: "Corea del Nord",
         reunion: "Réunion",
         san_Marino: "San Marino",
         senegal: "Senegal",
         sierra_Leone: "sierra leone",
         somalia: "Somalia",
         sudan: "Sudan",
         suriname: "Suriname",
         eswatini: "Eswatini",
         syria: "Siria",
         togo: "Togo",
         tonga: "Tonga",
         tunisia: "Tunisia",
         united_States_Virgin_Islands: "Isole Vergini Americane",
         uganda: "Uganda",
         uruguay: "Uruguay",
         vatican: "Vaticano",
         yemen: "Yemen",
         yugoslavia: "Jugoslavia",
         zambia: "Zambia",
         zimbabwe: "zimbabwe",
         china: "Cina",
     }
 };






